import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import {
  Card,
  CardHeader,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
  Button,
} from '@mui/material';

import { useFirestoreCollectionData } from 'hooks/firebase';
import { collection, orderBy, query, where } from 'firebase/firestore';
import { firestore, functions } from 'config/firebase';

import { endOfToday, startOfToday } from 'date-fns';
import { useFormik } from 'formik';
import { httpsCallable } from 'firebase/functions';
import { useToasts } from 'react-toast-notifications';
import * as yup from 'yup';
import { useCollectionData } from 'react-firebase-hooks/firestore';

const validationSchema = yup.object({
  id: yup.string().required('Required').label('Event'),
});

export default function AppTriggerSendEvent() {
  const { addToast } = useToasts();
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      id: '',
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      // console.log({ values });
      const sendEventNotif = httpsCallable(functions, 'sendEventNotif');
      const { data } = sendEventNotif({ ...values });

      // if (data?.status === 'success') {
      //   resetForm();
      // }

      // setSubmitting(false);
      // addToast(data?.message, { autoDismiss: true, appearance: data?.status });
    },
  });

  const [events = [], loading, error] = useCollectionData(
    query(
      collection(firestore, 'events'),
      where('datetime', '>=', startOfToday()),
      where('datetime', '<=', endOfToday()),
      orderBy('datetime', 'desc')
    )
  );

  return (
    <Card>
      <CardHeader title="Trigger Send Event Notification" />
      <Box
        m={2}
        sx={
          {
            // display: 'flex',
            // flexDirection: 'column',
            // alignItems: 'center',
          }
        }
      >
        <FormControl error={!!formik.errors?.id}>
          <FormLabel>Today's Events {`(${formik?.errors?.id || ''})`}</FormLabel>
          <RadioGroup id="id" {...formik.getFieldProps('id')}>
            {events?.map?.((e, i) => {
              return (
                <FormControlLabel
                  key={i}
                  value={e?.id}
                  control={<Radio size="small" />}
                  label={`${e?.name} - ${e?.time}`}
                />
              );
            })}
          </RadioGroup>
        </FormControl>

        <Button
          fullWidth
          variant="contained"
          disabled={formik?.isSubmitting}
          onClick={formik?.handleSubmit}
        >
          Send Event Notification
        </Button>
      </Box>
    </Card>
  );
}
