import { countBy, merge, uniq } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';
import { useFirestoreCollectionData } from 'hooks/firebase';
import { firestore } from 'config/firebase';
import { collection, query } from 'firebase/firestore';

// ----------------------------------------------------------------------

export default function AppConversionRates() {
  const [allUsers = [], loading] = useFirestoreCollectionData(
    query(collection(firestore, 'users')),
    {
      listen: true,
      transform: (data) => {
        return {
          ...data,
        };
      },
    }
  );

  const filtered = allUsers?.filter((u) => u?.deviceManufacturer).map((u) => u?.deviceManufacturer);

  const CHART_DATA = [{ data: Object.values(countBy(filtered)) }];

  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: true },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`,
        },
      },
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '28%', borderRadius: 2 },
    },
    xaxis: {
      categories: uniq(filtered),
    },
  });

  return (
    <Card>
      <CardHeader
        title="Users By Device"
        // subheader="(+43%) than last year"
      />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
