import { storage } from 'config/firebase';
import { ref, getDownloadURL, uploadBytesResumable, uploadBytes } from 'firebase/storage';
import { filter } from 'lodash';

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  // stabilizedThis.sort((a, b) => {
  //   const order = comparator(a[0], b[0]);
  //   if (order !== 0) return order;
  //   return a[1] - b[1];
  // });
  if (query) {
    return filter(array, (i) => i.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export const handleRequestSort = (event, property, { order, orderBy, setOrder, setOrderBy }) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
};

export const handleSelectAllClick = (event, data, { setSelected }) => {
  if (event.target.checked) {
    const newSelecteds = data;
    // const newSelecteds = data.map((n) => n.name);
    setSelected(newSelecteds);
    return;
  }
  setSelected([]);
};

export const handleClick = (event, name, { selected, setSelected }) => {
  const selectedIndex = selected.indexOf(name);
  let newSelected = [];
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }
  setSelected(newSelected);
};

export const handleChangePage = (event, newPage, { setPage }) => {
  setPage(newPage);
};

export const handleChangeRowsPerPage = (event, { setRowsPerPage, setPage }) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

export const handleFilterByName = (event, { setFilterName }) => {
  setFilterName(event.target.value);
};

export const getStorageDownloadURL = async (file, name, path) => {
  const metadata = {
    contentType: 'image/jpeg',
  };

  const storageRef = ref(storage, `${path}/` + name);
  const uploadTask = await uploadBytes(storageRef, file, metadata);
  // console.log(uploadTask);
  const downloadURL = await getDownloadURL(uploadTask.ref);
  return downloadURL;
};

export const getNewStorageDownloadURL = async (remoteimageurl, name, path) => {
  const metadata = {
    contentType: 'image/jpeg',
  };

  const res = await fetch(
    `https://us-central1-the-outside-app.cloudfunctions.net/compressImage?imageURL=${remoteimageurl}&filePath=${path}/${name}`
    // {
    //   headers: {
    //     'Access-Control-Allow-Origin': '*',
    //   },
    // }
  );

  const { success, message } = await res.json();
  // const res = await fetch(encodeURI(remoteimageurl), {
  //   // const res = await fetch('https://scrappy-php.herokuapp.com/?url=' + encodeURI(remoteimageurl), {
  //   // headers: {
  //   //   'Access-Control-Allow-Origin': '*',
  //   // },
  // });

  // const resBlob = await res.blob();
  // // console.log(resBlob);

  // const storageRef = ref(storage, `${path}/` + name);
  // const snapshot = await uploadBytes(storageRef, resBlob, metadata);
  // // console.log(snapshot);
  // const downloadURL = await getDownloadURL(snapshot.ref);

  if (success) {
    return message;
  } else {
    return '';
  }
  return downloadURL;
};
