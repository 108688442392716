import { useState } from 'react';

// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Avatar,
  Checkbox,
  TableRow,
  TableCell,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
// components
import {
  collection,
  query,
  orderBy as forderBy,
  deleteDoc,
  doc,
  where,
  updateDoc,
} from 'firebase/firestore';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';

//
import { DefaultTable, DefaultTablePagination } from '../components/common/DefaultTable';
import {
  applySortFilter,
  getComparator,
  handleChangePage,
  handleClick,
  handleFilterByName,
  handleRequestSort,
  handleSelectAllClick,
  handleChangeRowsPerPage,
} from '../utils';
import DefaultTableToolbar from '../components/common/DefaultTableToolbar';
import { firestore, functions } from '../config/firebase';
import { useFirestoreCollectionData } from '../hooks/firebase';
import { capitalize, map } from 'lodash';

import { styled } from '@mui/material/styles';
import Label from 'components/Label';
import DefaultTableMoreMenu from 'components/common/DefaultTableMoreMenu';
import { capitalCase } from 'change-case';
import { useToasts } from 'react-toast-notifications';
import { httpsCallable } from 'firebase/functions';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'description', label: 'Description', alignRight: false },
  { id: 'venue', label: 'Venue', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'popularity', label: 'Popularity', alignRight: false },
  { id: 'muted', label: 'Muted', alignRight: false },
  { id: 'deleted', label: 'Deleted', alignRight: false },
  { id: 'repeat', label: 'Repeat', alignRight: false },
  { id: 'ticketPrices', label: 'Ticket Prices', alignRight: false },
  { id: 'ticketPurchasePoints', label: 'Ticket Purchase Points', alignRight: false },
  { id: 'imgUrls', label: 'Image URLs', alignRight: false },
  { id: 'id', label: 'ID', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

const StyledA = styled('a')(({ theme }) => ({}));

export default function UserEvents() {
  const { addToast } = useToasts();
  const sendEventNotif = httpsCallable(functions, 'sendEventNotif');

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [allEvents = [], loading] = useFirestoreCollectionData(
    query(collection(firestore, 'events'), forderBy('updatedAt', 'desc')),
    {
      listen: true,
      transform: (data) => {
        // console.log({ data });
        return {
          ...data,
          venueName: data?.venue?.name,
          venueCoordinates: `${data?.venue?.location?.coordinates?.lat}, ${data?.venue?.location?.coordinates?.lng}`,
          category: map(data?.category, (v) => capitalize(v?.replaceAll('-', ' '))),
          ticketPurchasePoints: map(data?.ticketPurchasePoints, (v) => `${v?.value}`).join(', '),
          ticketPrices: map(data?.ticketPrices, (v) => `${v?.name} - ${v?.value}`).join(', '),
        };
      },
    }
  );

  // console.log(allEvents[0]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allEvents.length) : 0;

  const filteredData = applySortFilter(allEvents, getComparator(order, orderBy), filterName);

  const isNotFound = !loading && filteredData.length === 0;

  const renderContent = () => {
    return filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
      const {
        id,
        name,
        datetime,
        venueName,
        venueCoordinates,
        repeat = '',
        images = [],
        ticketPurchasePoints,
        ticketPrices,
        venue,
        popularity,
        muted,
        deleted,
      } = row;
      const isItemSelected = selected.indexOf(name) !== -1;

      // console.log({ venue });

      const onDelete = async (id) => {
        const res = window.confirm(`Are you sure you want to delete`);
        if (res) {
          await deleteDoc(doc(collection(firestore, 'events'), id));
        }
      };

      return (
        <TableRow
          hover
          key={id}
          tabIndex={-1}
          role="checkbox"
          selected={isItemSelected}
          aria-checked={isItemSelected}
        >
          <TableCell padding="checkbox">
            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
          </TableCell>
          <TableCell component="th" scope="row" padding="none">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar alt={name} src={images[0] || null} />
              <Typography variant="subtitle2" noWrap>
                {name}
              </Typography>
            </Stack>
          </TableCell>
          {/* <TableCell align="left">{description}</TableCell> */}
          <TableCell align="left">
            <StyledA
              sx={{
                color: 'primary.main',
              }}
              href={`https://www.google.com/maps/search/${venueCoordinates}/@${venueCoordinates}`}
              target="_blank"
            >
              {venueName}
            </StyledA>
          </TableCell>
          <TableCell
            align="left"
            sx={{
              minWidth: 250,
            }}
          >
            {datetime.toDate().toGMTString()}
          </TableCell>
          <TableCell align="left">
            <Select
              value={popularity}
              onChange={async (e) => {
                await updateDoc(doc(collection(firestore, 'events'), id), {
                  popularity: e.target.value,
                });
              }}
              variant="outlined"
            >
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((c, i) => (
                <MenuItem key={i} value={c}>
                  {c}
                </MenuItem>
              ))}
            </Select>
          </TableCell>

          <TableCell align="left">
            <Select
              value={muted}
              onChange={async (e) => {
                await updateDoc(doc(collection(firestore, 'events'), id), {
                  muted: e.target.value,
                });
              }}
              variant="outlined"
            >
              {[0, 1]?.map((c, i) => (
                <MenuItem key={i} value={c}>
                  {c}
                </MenuItem>
              ))}
            </Select>
          </TableCell>

          <TableCell align="left">
            <Select
              value={deleted}
              onChange={async (e) => {
                await updateDoc(doc(collection(firestore, 'events'), id), {
                  deleted: e.target.value,
                });
              }}
              variant="outlined"
            >
              {[0, 1]?.map((c, i) => (
                <MenuItem key={i} value={c}>
                  {c}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
          <TableCell align="left">{capitalCase(repeat)}</TableCell>
          <TableCell align="left">{ticketPrices}</TableCell>
          <TableCell align="left">{ticketPurchasePoints}</TableCell>
          <TableCell align="left">{images.join(',')}</TableCell>
          <TableCell align="left">{id}</TableCell>

          <TableCell align="right">
            <DefaultTableMoreMenu
              to={`/dashboard/manage/userevent/${id}`}
              onDelete={() => onDelete(id)}
              onNotifyAll={async () => {
                const { data } = await sendEventNotif({ id });
                addToast(data?.message, { autoDismiss: true, appearance: data?.status });
              }}
            />
          </TableCell>
        </TableRow>
      );
    });
  };

  // return null;

  return (
    <Page title="All Other Events | Outsida">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            User Added Events
          </Typography>
        </Stack>

        <Card>
          <DefaultTableToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={(e) => handleFilterByName(e, { setFilterName })}
          />

          <Scrollbar>
            <DefaultTable
              renderContent={renderContent}
              TABLE_HEAD={TABLE_HEAD}
              data={allEvents}
              emptyRows={emptyRows}
              filterName={filterName}
              filteredData={filteredData}
              handleClick={(e, name) => handleClick(e, name, { selected, setSelected })}
              handleRequestSort={(event, property) =>
                handleRequestSort(event, property, { order, orderBy, setOrder, setOrderBy })
              }
              handleSelectAllClick={(e) => handleSelectAllClick(e, allEvents, { setSelected })}
              isNotFound={isNotFound}
              loading={loading}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              selected={selected}
            />
          </Scrollbar>

          <DefaultTablePagination
            data={allEvents}
            handleChangePage={(e, newPage) => handleChangePage(e, newPage, { setPage })}
            handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e, { setPage, setRowsPerPage })}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
