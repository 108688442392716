import { useState, useEffect } from 'react';

import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Link,
  Container,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Button,
  ButtonGroup,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Avatar,
  Grid,
} from '@mui/material';
// layouts
// components
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import { capitalize, get, intersectionWith, kebabCase, pick, map, isEqual } from 'lodash';
import {
  collection,
  doc,
  setDoc,
  Timestamp,
  where,
  query,
  getDocs,
  getDoc,
  updateDoc,
} from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useToasts } from 'react-toast-notifications';
import { format } from 'date-fns';
import { MHidden } from '../components/@material-extend';
import Page from '../components/Page';
import { firestore } from '../config/firebase';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LoadingButton, LocalizationProvider, DateTimePicker } from '@mui/lab';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  // margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  // minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(6, 0),
}));

// ----------------------------------------------------------------------

const AddEventSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').required('Event name required'),
  description: Yup.string(),
  participants: Yup.string(),
  activities: Yup.string(),
  popularity: Yup.number().default(0),
  category: Yup.array().min(1, 'Category must be selected'),
  venue: Yup.object().required('Venue is required'),
  images: Yup.string(),
  // images: Yup.string().required('At least one image url is required'),
  datetime: Yup.string().required('Date and time required'),
  website: Yup.string().url("Value must be a valid URL starting with 'https://www'"),
  socialMediaHandles: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'), // these constraints take precedence
      handle: Yup.string().required('Handle is required'), // these constraints take precedence
    })
  ),
  ticketPurchasePoints: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required('Type is required'),
      // type: Yup.mixed()
      // .oneOf(['phone', 'website', 'email', 'whatsapp'], (val) => {
      //   return `Type can only be one of ${val?.values}`;
      // })
      // .required('Type is required'),
      value: Yup.string()
        .when('type', {
          is: (val) => val == 'phone' || val === 'whatsapp',
          then: (schema) =>
            schema.min(10, 'Must be 10 characters').max(10, 'Must be 10 characters'),
        })
        .when('type', {
          is: 'email', // alternatively: (val) => val == true
          then: (schema) => schema.email('Must be a valid email address'),
        })
        .when('type', {
          is: 'website', // alternatively: (val) => val == true
          then: (schema) => schema.url("Value must be a valid URL starting with 'https://www'"),
        })
        .required('Value is required'),
    })
  ),
  ticketPrices: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      value: Yup.string().required('Value is required'),
    })
  ),
});

const initialValues = {
  name: '',
  description: '',
  participants: '',
  activities: '',
  category: [],
  socialMediaHandles: [],
  ticketPurchasePoints: [],
  ticketPrices: [],
  venue: '',
  images: '',
  datetime: '',
  website: '',
  repeat: '',
  popularity: 0,
  noOfRepeat: 0,
};

function AddEventForm({ formik, ecategories = [], venues, isEditing }) {
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } =
    formik;

  return (
    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}

        {/* <FormControl fullWidth error={Boolean(touched.images && errors.images)}>
          <FileUpload fieldName="images" />
          <FormHelperText>{touched.images && errors.images}</FormHelperText>
        </FormControl> */}

        <TextField
          fullWidth
          label="Name"
          {...getFieldProps('name')}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
        />

        <TextField
          fullWidth
          label="Description"
          {...getFieldProps('description')}
          multiline
          rows={3}
          error={Boolean(touched.description && errors.description)}
          helperText={touched.description && errors.description}
        />
        {/* </Stack> */}

        {/* <FormControl fullWidth error={Boolean(touched.venue && errors.venue)}>
          <Autocomplete
            disablePortal
            fullWidth
            getOptionLabel={(option) => option.name || ''}
            options={venues}
            filterSelectedOptions
            {...getFieldProps('venue')}
            onChange={(e, value) => setFieldValue('venue', value)}
            renderInput={(params) => <TextField fullWidth {...params} label="Venue" />}
          />

          <FormHelperText>{touched.venue && errors.venue}</FormHelperText>
        </FormControl> */}

        {/* <FormControl fullWidth error={Boolean(touched.venue && errors.venue)}>
          <InputLabel>Venue</InputLabel>
          <Select {...getFieldProps('venue')} label="Venue">
            {venues?.map((c, i) => (
              <MenuItem key={i} value={c?.id}>
                {c?.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.venue && errors.venue}</FormHelperText>
        </FormControl> */}

        <FormControl fullWidth error={Boolean(touched.datetime && errors.datetime)}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Date/Time"
              {...getFieldProps('datetime')}
              onChange={(value) => setFieldValue('datetime', value)}
            />
          </LocalizationProvider>
          <FormHelperText>{touched.datetime && errors.datetime}</FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel id="repeat">Repeat</FormLabel>
          <RadioGroup
            aria-labelledby="repeat"
            defaultValue={values?.repeat}
            name="repeat"
            sx={{ flexDirection: 'row' }}
            {...getFieldProps('repeat')}
          >
            <FormControlLabel value="none" control={<Radio />} label="None" />
            <FormControlLabel value="daily" control={<Radio />} label="Daily" />
            <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
            <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
            <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
          </RadioGroup>
        </FormControl>

        <FormControl fullWidth error={Boolean(touched.noOfRepeat && errors.noOfRepeat)}>
          <Autocomplete
            disablePortal
            fullWidth
            // getOptionLabel={(option) => option.name}
            options={['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '15', '20', '30']}
            filterSelectedOptions
            {...getFieldProps('noOfRepeat')}
            onChange={(e, value) => setFieldValue('noOfRepeat', value)}
            renderInput={(params) => (
              <TextField fullWidth {...params} label="Number of times to repeat" />
            )}
          />

          <FormHelperText>{touched.category && errors.category}</FormHelperText>
        </FormControl>

        <FormControl fullWidth error={Boolean(touched.category && errors.category)}>
          <Autocomplete
            multiple
            disablePortal
            fullWidth
            getOptionLabel={(option) => option.name}
            options={ecategories}
            filterSelectedOptions
            {...getFieldProps('category')}
            onChange={(e, value) => setFieldValue('category', value)}
            renderInput={(params) => <TextField fullWidth {...params} label="Category" />}
          />

          <FormHelperText>{touched.category && errors.category}</FormHelperText>
        </FormControl>

        <TextField
          fullWidth
          label="Image URLs (separate with a comma)"
          {...getFieldProps('images')}
          multiline
          rows={5}
          error={Boolean(touched.images && errors.images)}
          helperText={touched.images && errors.images}
        />

        <TextField
          fullWidth
          label="Website"
          {...getFieldProps('website')}
          error={Boolean(touched.website && errors.website)}
          helperText={touched.website && errors.website}
        />

        <TextField
          fullWidth
          label="Activities"
          {...getFieldProps('activities')}
          multiline
          rows={3}
          error={Boolean(touched.activities && errors.activities)}
          helperText={touched.activities && errors.activities}
        />

        <TextField
          fullWidth
          label="Participants"
          {...getFieldProps('participants')}
          multiline
          rows={3}
          error={Boolean(touched.participants && errors.participants)}
          helperText={touched.participants && errors.participants}
        />

        <FormControl fullWidth error={Boolean(touched.popularity && errors.popularity)}>
          <InputLabel>Popularity</InputLabel>
          <Select {...getFieldProps('popularity')} label="Popularity" variant="outlined">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((c, i) => (
              <MenuItem key={i} value={c}>
                {c}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.popularity && errors.popularity}</FormHelperText>
        </FormControl>

        <FieldArray
          name="ticketPrices"
          render={(arrayHelpers) => {
            // console.log(arrayHelpers);
            return (
              <>
                {values?.ticketPrices?.length ? (
                  <>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ paddingBottom: 0, paddingLeft: 1.5 }}
                    >
                      Ticket Prices
                    </Typography>
                    {values.ticketPrices.map((ticketPrice, index) => {
                      return (
                        <Stack key={index} direction="row" spacing={3}>
                          <Field name={`ticketPrices.${index}.name`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(errors);
                              return (
                                <TextField
                                  variant="standard"
                                  size="small"
                                  fullWidth
                                  label="Name"
                                  {...field}
                                  error={Boolean(
                                    touched.ticketPrices?.[index]?.name &&
                                      errors.ticketPrices?.[index]?.name
                                  )}
                                  helperText={
                                    touched.ticketPrices?.[index]?.name &&
                                    errors.ticketPrices?.[index]?.name
                                  }
                                />
                              );
                            }}
                          </Field>

                          <Field name={`ticketPrices.${index}.value`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(field, errors);
                              return (
                                <TextField
                                  variant="standard"
                                  size="small"
                                  fullWidth
                                  label="Value"
                                  {...field}
                                  error={Boolean(
                                    touched.ticketPrices?.[index]?.value &&
                                      errors.ticketPrices?.[index]?.value
                                  )}
                                  helperText={
                                    touched.ticketPrices?.[index]?.value &&
                                    errors.ticketPrices?.[index]?.value
                                  }
                                />
                              );
                            }}
                          </Field>

                          <ButtonGroup variant="outlined">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                arrayHelpers.insert(index + 1, { name: '', value: '' })
                              } // insert an empty string at a position
                            >
                              +
                            </Button>
                          </ButtonGroup>
                        </Stack>
                      );
                    })}
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => arrayHelpers.push({ name: '', value: '' })}
                  >
                    Ticket Prices
                  </Button>
                )}
              </>
            );
          }}
        />

        <FieldArray
          name="ticketPurchasePoints"
          render={(arrayHelpers) => {
            // console.log(arrayHelpers);
            return (
              <>
                {values?.ticketPurchasePoints?.length ? (
                  <>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ paddingBottom: 0, paddingLeft: 1.5 }}
                    >
                      Ticket Purchase Points
                    </Typography>
                    {values.ticketPurchasePoints.map((ticketPurchasePoint, index) => {
                      return (
                        <Stack key={index} direction="row" spacing={3}>
                          <Field name={`ticketPurchasePoints.${index}.type`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(errors);
                              return (
                                <FormControl
                                  fullWidth
                                  error={Boolean(
                                    touched.ticketPurchasePoints?.[index]?.type &&
                                      errors.ticketPurchasePoints?.[index]?.type
                                  )}
                                >
                                  <InputLabel>Type</InputLabel>
                                  <Select {...field} label="Type" variant="standard">
                                    {['phone', 'website', 'email', 'whatsapp']?.map((c, i) => (
                                      <MenuItem key={i} value={c}>
                                        {c}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText>
                                    {touched.ticketPurchasePoints?.[index]?.type &&
                                      errors.ticketPurchasePoints?.[index]?.type}
                                  </FormHelperText>
                                </FormControl>

                                // <TextField
                                //   variant="standard"
                                //   size="small"
                                //   fullWidth
                                //   label="Type"
                                //   {...field}
                                //   error={Boolean(
                                //     touched['ticketPurchasePoints']?.[index]?.type &&
                                //       errors['ticketPurchasePoints']?.[index]?.type
                                //   )}
                                //   helperText={
                                //     touched['ticketPurchasePoints']?.[index]?.type &&
                                //     errors['ticketPurchasePoints']?.[index]?.type
                                //   }
                                // />
                              );
                            }}
                          </Field>

                          <Field name={`ticketPurchasePoints.${index}.value`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(field, errors);
                              return (
                                <TextField
                                  variant="standard"
                                  size="small"
                                  fullWidth
                                  label="Value"
                                  {...field}
                                  error={Boolean(
                                    touched.ticketPurchasePoints?.[index]?.value &&
                                      errors.ticketPurchasePoints?.[index]?.value
                                  )}
                                  helperText={
                                    touched.ticketPurchasePoints?.[index]?.value &&
                                    errors.ticketPurchasePoints?.[index]?.value
                                  }
                                />
                              );
                            }}
                          </Field>

                          <ButtonGroup variant="outlined">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                arrayHelpers.insert(index + 1, { type: '', value: '' })
                              } // insert an empty string at a position
                            >
                              +
                            </Button>
                          </ButtonGroup>
                        </Stack>
                      );
                    })}
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => arrayHelpers.push({ type: '', value: '' })}
                  >
                    Ticket Purchase Points
                  </Button>
                )}
              </>
            );
          }}
        />

        <FieldArray
          name="socialMediaHandles"
          render={(arrayHelpers) => {
            return (
              <>
                {values?.socialMediaHandles?.length ? (
                  <>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ paddingBottom: 0, paddingLeft: 1.5 }}
                    >
                      Social Media Handles
                    </Typography>
                    {values.socialMediaHandles.map((friend, index) => {
                      return (
                        <Stack key={index} direction="row" spacing={3}>
                          <Field name={`socialMediaHandles.${index}.name`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(field, errors);
                              return (
                                <FormControl
                                  fullWidth
                                  error={Boolean(
                                    touched.socialMediaHandles?.[index]?.name &&
                                      errors.socialMediaHandles?.[index]?.name
                                  )}
                                >
                                  <InputLabel>Name</InputLabel>
                                  <Select {...field} label="Name" variant="standard">
                                    {['instagram', 'twitter', 'facebook']?.map((c, i) => (
                                      <MenuItem key={i} value={c}>
                                        {c}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText>
                                    {touched.socialMediaHandles?.[index]?.name &&
                                      errors.socialMediaHandles?.[index]?.name}
                                  </FormHelperText>
                                </FormControl>

                                // <TextField
                                //   variant="standard"
                                //   size="small"
                                //   fullWidth
                                //   label="Name"
                                //   {...field}
                                //   error={Boolean(
                                //     touched['socialMediaHandles']?.[index]?.name &&
                                //       errors['socialMediaHandles']?.[index]?.name
                                //   )}
                                //   helperText={
                                //     touched['socialMediaHandles']?.[index]?.name &&
                                //     errors['socialMediaHandles']?.[index]?.name
                                //   }
                                // />
                              );
                            }}
                          </Field>

                          <Field name={`socialMediaHandles.${index}.handle`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(field, errors);
                              return (
                                <TextField
                                  variant="standard"
                                  size="small"
                                  fullWidth
                                  label="Handle"
                                  {...field}
                                  error={Boolean(
                                    touched.socialMediaHandles?.[index]?.handle &&
                                      errors.socialMediaHandles?.[index]?.handle
                                  )}
                                  helperText={
                                    touched.socialMediaHandles?.[index]?.handle &&
                                    errors.socialMediaHandles?.[index]?.handle
                                  }
                                />
                              );
                            }}
                          </Field>

                          <ButtonGroup variant="outlined" aria-label="outlined button group">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                arrayHelpers.insert(index + 1, { name: '', handle: '' })
                              } // insert an empty string at a position
                            >
                              +
                            </Button>
                          </ButtonGroup>
                        </Stack>
                      );
                    })}
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => arrayHelpers.push({ name: '', handle: '' })}
                  >
                    Add Social Media Handle
                  </Button>
                )}
              </>
            );
          }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {isEditing ? 'Update' : 'Add'}
        </LoadingButton>
      </Stack>
    </Form>
  );
}

export default function EditUserEvent() {
  const { addToast } = useToasts();
  const navigate = useNavigate();

  // react-router
  const { id } = useParams();

  const isEditing = !!id;
  const [initValues, setInitValues] = useState(initialValues);

  const [venues = []] = useCollectionData(collection(firestore, 'venues'));
  const [ecategories = []] = useCollectionData(collection(firestore, 'ecategories'));

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      let eventVenue = values?.venue;

      delete values?.venue;

      const data = {
        ...values,
        noOfRepeat: parseInt(values?.noOfRepeat),
        // venue: eventVenue,
        category: values?.category?.map((category) => category?.slug),
        datetime: Timestamp.fromDate(values?.datetime),
        date: format(values?.datetime, 'PPPP'),
        time: format(values?.datetime, 'p'),
        updatedAt: Timestamp.now(),
      };

      delete data?.images;
      delete data?.imgURLs;

      if (isEditing) {
        const editVenueRef = doc(collection(firestore, 'events'), values?.id);

        const newData = {
          ...data,
          imgURLs: values?.images ? values?.images.split(',').map((v) => v.trim()) : [],
          images: values?.images ? values?.images.split(',').map((v) => v.trim()) : [],
        };

        await updateDoc(editVenueRef, {
          ...newData,
        });

        navigate('/dashboard/user-events', { replace: true });
      }

      addToast('Success', { autoDismiss: true, appearance: 'success' });
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (venues?.length && ecategories?.length) {
      if (isEditing) {
        (async () => {
          const q = await getDoc(doc(collection(firestore, 'events'), id));
          const data = q.data();

          // console.log({ data });

          setInitValues({
            ...initialValues,
            ...data,
            noOfRepeat: String(data?.noOfRepeat || '0'),
            images: data?.images?.join(','),
            category: ecategories?.filter((ecategory) => data?.category?.includes(ecategory?.slug)),
            datetime: data?.datetime?.toDate(),
          });
        })();
      } else {
        setInitValues(initialValues);
      }
    }
  }, [venues, ecategories, isEditing, id]);

  return (
    <RootStyle title="Add/Edit Event | Outsida">
      <Formik
        initialValues={initValues}
        validationSchema={AddEventSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          const { values } = formik;
          // console.log(values?.images);
          return (
            <>
              <MHidden width="mdDown">
                <SectionStyle>
                  <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                    User Event Schema
                  </Typography>
                  <Grid container justifyContent="center">
                    <Avatar
                      variant="square"
                      alt={values?.name}
                      src={values?.images?.split(',')[0] || null}
                      sx={{ width: 400, height: 600, borderRadius: 5, marginBottom: 10 }}
                    />
                  </Grid>
                  {/* <img alt="register" src="/static/illustrations/illustration_register.png" /> */}
                  <Box sx={{}}>
                    <Typography
                      variant="subtitle2"
                      sx={{ px: 3, whiteSpace: 'pre-wrap' }}
                      // color="primary.secondary"
                    >
                      {`{
        "name": ${values?.name}, 

        "slug": ${kebabCase(values?.name)}, 

        "description": ${values?.description}, 

        "venue": ${venues?.find((venue) => venue?.id === values?.venue?.id)?.name || ''},

         "category": ${map(values?.category, (o) => o?.slug).join(', ') || ''}, 

          "images": [${values?.images}],

          "website": ${values?.website},

          "activities": ${values?.activities}, 

          "participants": ${values?.participants}, 

          "socialMediaHandles": ${JSON.stringify(values?.socialMediaHandles)}, 

          "ticketPurchasePoints": ${JSON.stringify(values?.ticketPurchasePoints)}, 
}`}
                    </Typography>
                  </Box>
                </SectionStyle>
              </MHidden>

              <Container>
                <ContentStyle>
                  <Box sx={{ mb: 5 }}>
                    <Typography variant="h4" gutterBottom>
                      {isEditing ? 'Edit Event' : 'Add New Event'}
                    </Typography>
                  </Box>

                  <AddEventForm
                    formik={formik}
                    venues={venues}
                    isEditing={isEditing}
                    ecategories={ecategories}
                  />
                </ContentStyle>
              </Container>
            </>
          );
        }}
      </Formik>
    </RootStyle>
  );
}
