import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const Container = styled('div')(({ theme }) => {
  return {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: '2px',
    backgroundColor: '#fafafa',
    color: theme.palette.text.secondary,
    outline: 'none',
    transition: 'border 0.24s ease-in-out'
  };
});

export function FileUpload({ fieldName }) {
  const { setFieldValue, values } = useFormikContext();

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    multiple: true,
    maxFiles: 3
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {parseFloat(file.size / 1000000).toFixed(1)} MB
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={file.path}>
      {file.path} - {parseFloat(file.size / 1000000).toFixed(1)} MB
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  useEffect(() => {
    setFieldValue(fieldName, acceptedFiles);
  }, [acceptedFiles]);

  return (
    <div className="container">
      <Container {...getRootProps({})}>
        <input
          {...getInputProps({
            // value: values?.images
          })}
        />
        <p>Drag 'n' drop files here, or click to select files</p>
        <em>(Only *.jpeg and *.png images will be accepted)</em>
        <aside>
          {acceptedFileItems?.length > 0 && (
            <>
              <h4>Accepted files</h4>
              <ul>{acceptedFileItems}</ul>
            </>
          )}

          {fileRejectionItems?.length > 0 && (
            <>
              <h4>Rejected files</h4>
              <ul>{fileRejectionItems}</ul>
            </>
          )}
        </aside>
      </Container>
    </div>
  );
}
