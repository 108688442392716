import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import SearchNotFound from '../SearchNotFound';
import DefaultTableHead from './DefaultTableHead';

export const DefaultTable = ({
  order,
  orderBy,
  TABLE_HEAD,
  data,
  selected,
  handleRequestSort,
  handleSelectAllClick,
  emptyRows,
  isNotFound,
  loading,
  filterName,
  renderContent = () => {},
}) => {
  return (
    <TableContainer sx={{ minWidth: 800 }}>
      <Table>
        <DefaultTableHead
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEAD}
          rowCount={data.length}
          numSelected={selected.length}
          onRequestSort={handleRequestSort}
          onSelectAllClick={handleSelectAllClick}
        />
        <TableBody>
          {renderContent()}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        {loading && (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                <Typography color="text.primary">Loading...</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        {isNotFound && (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                <SearchNotFound searchQuery={filterName} />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export const DefaultTablePagination = ({
  data,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => (
  <TablePagination
    rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
    component="div"
    count={data.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />
);
