import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  styled,
} from '@mui/material';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';

import { collection, query, orderBy as forderBy, deleteDoc, doc, where } from 'firebase/firestore';
//
import DefaultTableToolbar from '../components/common/DefaultTableToolbar';
import { DefaultTable, DefaultTablePagination } from '../components/common/DefaultTable';
import { useFirestoreCollectionData } from 'hooks/firebase';
import { firestore } from 'config/firebase';

import DefaultTableMoreMenu from 'components/common/DefaultTableMoreMenu';
import { capitalCase } from 'change-case';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'appInstallDate', label: 'Install Date', alignRight: false },
  { id: 'appLastOpenDate', label: 'Last Open Date', alignRight: false },
  { id: 'deviceManufacturer', label: 'Device', alignRight: false },
  { id: 'locationName', label: 'Location Name', alignRight: false },
  { id: 'expoToken', label: 'Notification Token', alignRight: false },
  { id: 'deviceID', label: 'Device ID', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  const itemA = orderBy.includes('Date')
    ? a[orderBy]?.toDate()
      ? new Date(a[orderBy]?.toDate())
      : null
    : a[orderBy];
  const itemB = orderBy.includes('Date')
    ? b[orderBy]?.toDate()
      ? new Date(b[orderBy]?.toDate())
      : null
    : b[orderBy];

  if (itemB < itemA) {
    return -1;
  }
  if (itemB > itemA) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  // console.log({ stabilizedThis });
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('appInstallDate');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [allUsers = [], loading] = useFirestoreCollectionData(
    query(collection(firestore, 'users'), forderBy('appInstallDate', 'desc')),
    {
      listen: true,
      transform: (data) => {
        // console.log({ data });
        return {
          ...data,
          locationName: data?.initialLocation?.locationName?.name || '',
          locationCity: data?.initialLocation?.locationName?.city || '',
          locationRegion: data?.initialLocation?.locationName?.region || '',
          locationCountry: data?.initialLocation?.locationName?.country || '',
        };
      },
    }
  );

  // console.log({ allUsers });

  const handleRequestSort = (event, property) => {
    console.log({ property });
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = allUsers.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allUsers.length) : 0;
  const filteredUsers = applySortFilter(allUsers, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;

  // console.log(filteredUsers[0]);

  const renderContent = () => {
    return filteredUsers
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row, i) => {
        const {
          appInstallDate,
          deviceID,
          deviceManufacturer,
          deviceMobileCarrier,
          deviceMobileNetwork,
          deviceNetworkType,
          expoToken,
          appLastOpenDate,
          locationName,
          locationCity,
          locationRegion,
          locationCountry,
        } = row;
        const isItemSelected = selected.indexOf(deviceID) !== -1;

        return (
          <TableRow
            hover
            key={i}
            tabIndex={-1}
            role="checkbox"
            selected={isItemSelected}
            aria-checked={isItemSelected}
          >
            <TableCell padding="checkbox">
              <Checkbox
                checked={isItemSelected}
                onChange={(event) => handleClick(event, deviceID)}
              />
            </TableCell>
            <TableCell
              align="left"
              sx={{
                minWidth: 250,
              }}
            >
              {appInstallDate.toDate().toGMTString()}
            </TableCell>

            <TableCell
              align="left"
              sx={{
                minWidth: 250,
              }}
            >
              {appLastOpenDate ? appInstallDate.toDate().toGMTString() : 'N/A'}
            </TableCell>

            <TableCell align="left">
              {deviceManufacturer} | {deviceMobileNetwork} | {deviceMobileCarrier} |{' '}
              {deviceNetworkType}
            </TableCell>

            <TableCell
              align="left"
              sx={{
                minWidth: 250,
              }}
            >
              {locationName} | {locationCity} | {locationRegion} | {locationCountry}
            </TableCell>
            <TableCell align="left">{expoToken}</TableCell>

            <TableCell component="th" scope="row" padding="none">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography variant="subtitle2" noWrap>
                  {deviceID}
                </Typography>
              </Stack>
            </TableCell>

            <TableCell align="right"></TableCell>
          </TableRow>
        );
      });
  };

  return (
    <Page title="Users | Outsida">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          {/* <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            New User
          </Button> */}
        </Stack>

        <Card>
          <DefaultTableToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <DefaultTable
              renderContent={renderContent}
              TABLE_HEAD={TABLE_HEAD}
              data={allUsers}
              emptyRows={emptyRows}
              filterName={filterName}
              filteredUsers={filteredUsers}
              handleClick={handleClick}
              handleRequestSort={handleRequestSort}
              handleSelectAllClick={handleSelectAllClick}
              isUserNotFound={isUserNotFound}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              selected={selected}
            />
          </Scrollbar>

          <DefaultTablePagination
            data={allUsers}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
