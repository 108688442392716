// material
import {
  Card,
  CardHeader,
  Box,
  Button,
  TextField,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  FormGroup,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useToasts } from 'react-toast-notifications';

import { format } from 'date-fns';
import { functions } from 'config/firebase';
import { httpsCallable } from 'firebase/functions';

const validationSchema = yup.object({
  number: yup.string().required('Required'),
  addEventBrite: yup.bool(),
  // addEventBrite: yup.bool().oneOf([true], 'Field must be checked'),
});
//

export default function AppCurrentSubject() {
  const { addToast } = useToasts();

  const formik = useFormik({
    initialValues: {
      number: '',
      addEventBrite: true,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setSubmitting(true);
        console.log(format(new Date(), 'PPPp'));

        const addExtraEventsToEvents = httpsCallable(functions, 'addExtraEventsToEvents');
        const { data: eventsAddedSlugs } = await addExtraEventsToEvents({
          no: values?.number,
          addEventBrite: values?.addEventBrite,
        });

        console.log({ eventsAddedSlugs });

        if (eventsAddedSlugs?.length) {
          resetForm();
          addToast(eventsAddedSlugs?.length + ' items added', {
            autoDismiss: true,
            appearance: 'success',
          });
        }
        console.log(format(new Date(), 'PPPp'));
      } catch (error) {
        console.log(error);
        console.log(error?.message);
        console.log(format(new Date(), 'PPPp'));
      }

      setSubmitting(false);
    },
  });

  return (
    <Card>
      <CardHeader
        title="Populate ExtraTickets"
        subheader="eg. https://egotickets.com/events/page/1 (the number at the end is what you enter here, separated by commas)"
      />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Box sx={{ justifyContent: 'center', width: '100%' }}>
          <Box>
            <FormControlLabel
              control={<Checkbox {...formik.getFieldProps('addEventBrite')} />}
              label="Run Eventbrite"
            />
            <TextField
              id="number"
              label="ExtraTickets Event Page Numbers"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!formik?.errors?.number}
              helperText={formik.errors.number}
              {...formik.getFieldProps('number')}
            />

            <FormHelperText
              sx={{
                color: 'red',
              }}
            >
              {formik.errors.addEventBrite}
            </FormHelperText>

            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={formik.handleSubmit}
              disabled={formik.isSubmitting}
            >
              Populate
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
