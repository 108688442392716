import { useState } from 'react';

// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Avatar,
  Checkbox,
  TableRow,
  TableCell,
} from '@mui/material';
// components
import { collection, query, orderBy as forderBy } from 'firebase/firestore';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';

//
import { DefaultTable, DefaultTablePagination } from '../components/common/DefaultTable';
import {
  applySortFilter,
  getComparator,
  handleChangePage,
  handleClick,
  handleFilterByName,
  handleRequestSort,
  handleSelectAllClick,
  handleChangeRowsPerPage,
} from '../utils';
import DefaultTableToolbar from '../components/common/DefaultTableToolbar';
import { firestore } from '../config/firebase';
import { useFirestoreCollectionData } from '../hooks/firebase';
import { capitalize } from 'lodash';

import { styled } from '@mui/material/styles';
import Label from 'components/Label';
import DefaultTableMoreMenu from 'components/common/DefaultTableMoreMenu';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'coordinates', label: 'Coordinates', alignRight: false },
  // { id: 'location', label: 'Location', alignRight: false },
  // { id: 'category', label: 'Category', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'imgUrls', label: 'Image URLs', alignRight: false },
  // { id: 'website', label: 'Website', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------
const StyledA = styled('a')(({ theme }) => ({}));

export default function Venues() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [allVenues = [], loading] = useFirestoreCollectionData(
    query(collection(firestore, 'venues'), forderBy('updatedAt', 'desc')),
    {
      listen: true,
      transform: (data) => {
        return {
          ...data,
          location: data?.location?.name,
          coordinates: `${data?.location?.coordinates?.lat}, ${data?.location?.coordinates?.lng}`,
          category: capitalize(data?.category?.replaceAll('-', ' ')),
        };
      },
    }
  );

  // console.log(allVenues);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allVenues.length) : 0;

  const filteredData = applySortFilter(allVenues, getComparator(order, orderBy), filterName);

  const isNotFound = !loading && filteredData.length === 0;

  const renderContent = () => {
    return filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
      const {
        id,
        name,
        category,
        coordinates,
        location,
        phone,
        website,
        description,
        images = [],
      } = row;
      const isItemSelected = selected.indexOf(name) !== -1;

      // console.log(images);

      return (
        <TableRow
          hover
          key={id}
          tabIndex={-1}
          role="checkbox"
          selected={isItemSelected}
          aria-checked={isItemSelected}
        >
          <TableCell padding="checkbox">
            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
          </TableCell>
          <TableCell component="th" scope="row" padding="none">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar alt={name} src={images[0] || null} />
              <Typography variant="subtitle2" noWrap>
                {name}
              </Typography>
            </Stack>
          </TableCell>
          <TableCell align="left">{description}</TableCell>
          <TableCell align="left">
            <StyledA
              sx={{
                color: 'primary.main',
              }}
              href={`https://www.google.com/maps/search/${coordinates}/@${coordinates}`}
              target="_blank"
            >
              {coordinates}
            </StyledA>
          </TableCell>
          {/* <TableCell align="left">{location}</TableCell> */}
          {/* <TableCell align="left">
            <Label variant="ghost" color={'success'}>
              {category}
            </Label>
          </TableCell> */}
          <TableCell align="left">{phone}</TableCell>
          <TableCell align="left">
            {images.map((i, index) => {
              return (
                <StyledA
                  key={index}
                  sx={{
                    color: 'primary.main',
                  }}
                  href={i}
                  target="_blank"
                >
                  {i}
                  {', '}
                </StyledA>
              );
            })}
          </TableCell>

          <TableCell align="right">
            <DefaultTableMoreMenu to={`/dashboard/manage/venue/${id}`} />
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Page title="Venues | Outsida">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Venues
          </Typography>
        </Stack>

        <Card>
          <DefaultTableToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={(e) => handleFilterByName(e, { setFilterName })}
          />

          <Scrollbar>
            <DefaultTable
              renderContent={renderContent}
              TABLE_HEAD={TABLE_HEAD}
              data={allVenues}
              emptyRows={emptyRows}
              filterName={filterName}
              filteredData={filteredData}
              handleClick={(e, name) => handleClick(e, name, { selected, setSelected })}
              handleRequestSort={(event, property) =>
                handleRequestSort(event, property, { order, orderBy, setOrder, setOrderBy })
              }
              handleSelectAllClick={(e) => handleSelectAllClick(e, allVenues, { setSelected })}
              isNotFound={isNotFound}
              loading={loading}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              selected={selected}
            />
          </Scrollbar>

          <DefaultTablePagination
            data={allVenues}
            handleChangePage={(e, newPage) => handleChangePage(e, newPage, { setPage })}
            handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e, { setPage, setRowsPerPage })}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
