import { Icon } from '@iconify/react';
import smartphoneFill from '@iconify/icons-eva/smartphone-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection, query, where } from 'firebase/firestore';
import { firestore } from 'config/firebase';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter,
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.error.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.error.dark, 0)} 0%, ${alpha(
    theme.palette.error.dark,
    0.24
  )} 100%)`,
}));

// ----------------------------------------------------------------------

export default function AppBugReports() {
  const [notifications = []] = useCollectionData(
    query(collection(firestore, 'users'), where('expoToken', '!=', null)),
    {}
  );

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={smartphoneFill} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{notifications?.length}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }} onClick={() => {}}>
        Notifications Turned On
      </Typography>
    </RootStyle>
  );
}
