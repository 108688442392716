import { Form, FieldArray, Field } from 'formik';

// material
import {
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Button,
  ButtonGroup,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { FileUpload } from '../../common/FileUpload';

// ----------------------------------------------------------------------

export default function AddVenueForm({ formik, vcategories, isEditing }) {
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } =
    formik;

  // console.log(values);
  // console.log({ ...getFieldProps('socialMediaHandles') });

  return (
    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}

        {/* <FormControl fullWidth error={Boolean(touched.images && errors.images)}>
          <FileUpload fieldName="images" />
          <FormHelperText>{touched.images && errors.images}</FormHelperText>
        </FormControl> */}

        <TextField
          fullWidth
          label="Name"
          {...getFieldProps('name')}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
        />
        <TextField
          fullWidth
          label="Other Names"
          {...getFieldProps('otherNames')}
          multiline
          rows={3}
          error={Boolean(touched.otherNames && errors.otherNames)}
          helperText={touched.otherNames && errors.otherNames}
        />

        <TextField
          fullWidth
          label="Description"
          {...getFieldProps('description')}
          multiline
          rows={3}
          error={Boolean(touched.description && errors.description)}
          helperText={touched.description && errors.description}
        />
        {/* </Stack> */}

        <FormControl fullWidth error={Boolean(touched.category && errors.category)}>
          <InputLabel>Category</InputLabel>
          <Select {...getFieldProps('category')} label="Category">
            {vcategories?.map((c, i) => (
              <MenuItem key={i} value={c?.id}>
                {c?.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.category && errors.category}</FormHelperText>
        </FormControl>

        <FormControl fullWidth error={Boolean(touched.location && errors.location)}>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ paddingBottom: 0.5, paddingLeft: 1.5 }}
          >
            Location
          </Typography>

          <GooglePlacesAutocomplete
            apiKey="AIzaSyBBQL3_SOU994mZEE0sIkeOeE1u_0uw0fM"
            autocompletionRequest={{
              componentRestrictions: {
                country: ['gh'],
              },
            }}
            selectProps={{
              placeholder: 'Enter venue location',
              styles: {
                input: (provided) => ({
                  ...provided,
                  padding: '12px 0',
                }),
                control: (provided) => ({
                  ...provided,
                  borderRadius: '8px',
                }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 10,
                }),
              },
              value: values?.location,
              onChange: (e) => {
                console.log(e);
                setFieldValue('location', e);
              },
            }}
          />
          <FormHelperText>{touched.location && errors.location}</FormHelperText>
        </FormControl>

        <TextField
          fullWidth
          label="Phone Number"
          {...getFieldProps('phone')}
          error={Boolean(touched.phone && errors.phone)}
          helperText={touched.phone && errors.phone}
        />

        <TextField
          fullWidth
          label="Website Address"
          placeholder="https://www.xxx.com"
          {...getFieldProps('website')}
          error={Boolean(touched.website && errors.website)}
          helperText={touched.website && errors.website}
        />

        <TextField
          fullWidth
          label="Opening Times"
          {...getFieldProps('openingTimes')}
          multiline
          rows={6}
          error={Boolean(touched.openingTimes && errors.openingTimes)}
          helperText={touched.openingTimes && errors.openingTimes}
        />

        <TextField
          fullWidth
          label="Image URLs (separate with a comma)"
          {...getFieldProps('images')}
          multiline
          rows={3}
          error={Boolean(touched.images && errors.images)}
          helperText={touched.images && errors.images}
        />

        <FieldArray
          name="socialMediaHandles"
          render={(arrayHelpers) => {
            return (
              <>
                {values?.socialMediaHandles?.length ? (
                  <>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ paddingBottom: 0, paddingLeft: 1.5 }}
                    >
                      Social Media Handles
                    </Typography>
                    {values.socialMediaHandles.map((friend, index) => {
                      return (
                        <Stack key={index} direction="row" spacing={3}>
                          <Field name={`socialMediaHandles.${index}.name`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(field, errors);
                              return (
                                <FormControl
                                  fullWidth
                                  error={Boolean(
                                    touched.socialMediaHandles?.[index]?.name &&
                                      errors.socialMediaHandles?.[index]?.name
                                  )}
                                >
                                  <InputLabel>Name</InputLabel>
                                  <Select {...field} label="Name" variant="standard">
                                    {['instagram', 'twitter', 'facebook']?.map((c, i) => (
                                      <MenuItem key={i} value={c}>
                                        {c}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText>
                                    {touched.socialMediaHandles?.[index]?.name &&
                                      errors.socialMediaHandles?.[index]?.name}
                                  </FormHelperText>
                                </FormControl>
                              );
                            }}
                          </Field>

                          <Field name={`socialMediaHandles.${index}.handle`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(field, errors);
                              return (
                                <TextField
                                  variant="standard"
                                  size="small"
                                  fullWidth
                                  label="Handle"
                                  {...field}
                                  error={Boolean(
                                    touched['socialMediaHandles']?.[index]?.handle &&
                                      errors['socialMediaHandles']?.[index]?.handle
                                  )}
                                  helperText={
                                    touched['socialMediaHandles']?.[index]?.handle &&
                                    errors['socialMediaHandles']?.[index]?.handle
                                  }
                                />
                              );
                            }}
                          </Field>

                          <ButtonGroup variant="outlined" aria-label="outlined button group">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                arrayHelpers.insert(index + 1, { name: '', handle: '' })
                              } // insert an empty string at a position
                            >
                              +
                            </Button>
                          </ButtonGroup>
                        </Stack>
                      );
                    })}
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => arrayHelpers.push({ name: '', handle: '' })}
                  >
                    Add Social Media Handle
                  </Button>
                )}
              </>
            );
          }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {isEditing ? 'Update' : 'Add'}
        </LoadingButton>
      </Stack>
    </Form>
  );
}
