import { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Avatar, Box, Card, Container, Grid, Typography } from '@mui/material';
// layouts
// components
import { Formik } from 'formik';
import * as Yup from 'yup';
import { get, kebabCase, pick, map } from 'lodash';
import {
  collection,
  doc,
  setDoc,
  Timestamp,
  where,
  query,
  getDocs,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useToasts } from 'react-toast-notifications';
import AddEvent from 'components/_dashboard/manage/AddEvent';
import { format } from 'date-fns';
import { MHidden } from '../components/@material-extend';
import Page from '../components/Page';
import { firestore, functions } from '../config/firebase';
import { httpsCallable } from 'firebase/functions';
import { getLatLng, geocodeByPlaceId } from 'react-google-places-autocomplete';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  // margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  // minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(6, 0),
}));

// ----------------------------------------------------------------------

const AddEventSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').required('Event name required'),
  description: Yup.string(),
  participants: Yup.string(),
  activities: Yup.string(),
  popularity: Yup.number().default(0),
  noOfRepeat: Yup.number().default(0),
  category: Yup.array().min(1, 'Category must be selected'),
  // venue: Yup.object().required('Venue is required'),
  location: Yup.object().required('Location is required'),
  images: Yup.string(),
  // images: Yup.string().required('At least one image url is required'),
  datetime: Yup.string().required('Date and time required'),
  website: Yup.string().url("Value must be a valid URL starting with 'https://www'"),
  socialMediaHandles: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'), // these constraints take precedence
      handle: Yup.string().required('Handle is required'), // these constraints take precedence
    })
  ),
  ticketPurchasePoints: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required('Type is required'),
      // type: Yup.mixed()
      // .oneOf(['phone', 'website', 'email', 'whatsapp'], (val) => {
      //   return `Type can only be one of ${val?.values}`;
      // })
      // .required('Type is required'),
      value: Yup.string()
        .when('type', {
          is: (val) => val == 'phone' || val === 'whatsapp',
          then: (schema) =>
            schema.min(10, 'Must be 10 characters').max(10, 'Must be 10 characters'),
        })
        .when('type', {
          is: 'email', // alternatively: (val) => val == true
          then: (schema) => schema.email('Must be a valid email address'),
        })
        .when('type', {
          is: 'website', // alternatively: (val) => val == true
          then: (schema) => schema.url("Value must be a valid URL starting with 'https://www'"),
        })
        .required('Value is required'),
    })
  ),
  ticketPrices: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'),
      value: Yup.string().required('Value is required'),
    })
  ),
});

const initialValues = {
  name: '',
  description: '',
  participants: '',
  activities: '',
  category: [],
  socialMediaHandles: [],
  ticketPurchasePoints: [],
  ticketPrices: [],
  // venue: '',
  location: '',
  images: '',
  datetime: '',
  website: '',
  repeat: '',
  popularity: 0,
  noOfRepeat: 0,
};

export default function Event() {
  const { addToast } = useToasts();
  const navigate = useNavigate();

  // react-router
  const { id } = useParams();

  const isEditing = !!id;
  const [initValues, setInitValues] = useState(initialValues);

  const [venues = []] = useCollectionData(collection(firestore, 'venues'));
  const [ecategories = []] = useCollectionData(collection(firestore, 'ecategories'));

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const slug = kebabCase(values?.name);
      const venueSlug = kebabCase(
        values?.location?.value?.structured_formatting?.main_text || slug || ''
      );

      const place_id = values?.location?.value?.place_id;
      const result = await geocodeByPlaceId(place_id);
      const latlng = await getLatLng(result[0]);

      const reverseGeocoding = async () => {
        const res = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${get(latlng, 'lat')},${get(
            latlng,
            'lng'
          )}&result_type=locality&key=AIzaSyBBQL3_SOU994mZEE0sIkeOeE1u_0uw0fM`
        );
        const data = await res.json();
        const formatted_address = data?.results?.[0]?.formatted_address || '';

        return formatted_address;
      };

      let data = {
        ...values,
        noOfRepeat: parseInt(values?.noOfRepeat),
        slug,
        category: values?.category?.map((category) => category?.slug),
        venueIdentifierSlug: venueSlug || slug,
        venue: {
          name: values?.location?.label,
          venueIdentifierSlug: venueSlug || slug,
          location: {
            nameOfCity: await reverseGeocoding(),
            coordinates: latlng,
          },
        },
        datetime: Timestamp.fromDate(values?.datetime),
        date: format(values?.datetime, 'PPPP'),
        time: format(values?.datetime, 'p'),
        updatedAt: Timestamp.now(),
        updateVenueField: false,
        isEditable: true,
      };

      // so we don't overwrite on edit
      delete data?.imgURLs;
      delete data?.images;
      //dont add location in base
      delete data.location && data.location;

      if (!isEditing) {
        const findSlugSnapshot = await getDocs(
          query(collection(firestore, 'events'), where('slug', '==', slug))
        );

        if (!findSlugSnapshot.empty) {
          return addToast('Event already exists', { autoDismiss: true, appearance: 'error' });
        }

        data.addedAt = Timestamp.now();
        const newEventRef = doc(collection(firestore, 'events'));

        await setDoc(newEventRef, {
          ...data,
          imgURLs: values?.images ? values?.images.split(',').map((v) => v.trim()) : [],
          images: values?.images ? values?.images.split(',').map((v) => v.trim()) : [],
          id: newEventRef?.id,
        });
        resetForm();
      }

      if (isEditing) {
        const editVenueRef = doc(collection(firestore, 'events'), values?.id);

        await updateDoc(editVenueRef, {
          ...data,
          imgURLs: values?.images ? values?.images.split(',').map((v) => v.trim()) : [],
          images: values?.images ? values?.images.split(',').map((v) => v.trim()) : [],
        });

        navigate('/dashboard/events', { replace: true });
      }

      addToast('Success', { autoDismiss: true, appearance: 'success' });
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (venues?.length && ecategories?.length) {
      if (isEditing) {
        (async () => {
          const q = await getDoc(doc(collection(firestore, 'events'), id));
          const data = q.data();

          setInitValues({
            ...initialValues,
            ...data,
            noOfRepeat: data?.noOfRepeat || 0,
            images: data?.imgURLs?.join(','),
            location: data?.venue?.location,
            category: ecategories?.filter((ecategory) => data?.category?.includes(ecategory?.slug)),
            datetime: data?.datetime?.toDate(),
          });
        })();
      } else {
        setInitValues(initialValues);
      }
    }
  }, [venues, ecategories, isEditing, id]);

  return (
    <RootStyle title="Add/Edit Event | Outsida">
      <Formik
        initialValues={initValues}
        validationSchema={AddEventSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          const { values } = formik;

          return (
            <>
              <MHidden width="mdDown">
                <SectionStyle>
                  <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                    Event Schema
                  </Typography>
                  <Grid container justifyContent="center">
                    <Avatar
                      variant="square"
                      alt={values?.name}
                      src={values?.images?.split(',')[0] || null}
                      sx={{ width: 400, height: 400, borderRadius: 5, marginBottom: 10 }}
                    />
                  </Grid>
                  {/* <img alt="register" src="/static/illustrations/illustration_register.png" /> */}
                  <Box sx={{}}>
                    <Typography
                      variant="subtitle2"
                      sx={{ px: 3, whiteSpace: 'pre-wrap' }}
                      // color="primary.secondary"
                    >
                      {`{
        "name": ${values?.name}, 

        "slug": ${kebabCase(values?.name)}, 

        "description": ${values?.description}, 

        "location": { 
          "name":  ${values?.location?.label || ''}, 
          "place_id":  ${values?.location?.value?.place_id || ''}, 
          "coordinates": "", 
          "description": ${values?.location?.value?.description || ''}, 
          "types": ${values?.location?.value?.types || ''} 
        },

         "category": ${map(values?.category, (o) => o?.slug).join(', ') || ''}, 

          "images": [${values?.images}],

          "website": ${values?.website},

          "activities": ${values?.activities}, 

          "participants": ${values?.participants}, 

          "socialMediaHandles": ${JSON.stringify(values?.socialMediaHandles)}, 

          "ticketPurchasePoints": ${JSON.stringify(values?.ticketPurchasePoints)}, 
}`}
                    </Typography>
                  </Box>
                </SectionStyle>
              </MHidden>

              <Container>
                <ContentStyle>
                  <Box sx={{ mb: 5 }}>
                    <Typography variant="h4" gutterBottom>
                      {isEditing ? 'Edit Event' : 'Add New Event'}
                    </Typography>
                  </Box>

                  <AddEvent
                    formik={formik}
                    venues={venues}
                    isEditing={isEditing}
                    ecategories={ecategories}
                  />
                </ContentStyle>
              </Container>
            </>
          );
        }}
      </Formik>
    </RootStyle>
  );
}
