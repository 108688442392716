// material
import {
  Card,
  CardHeader,
  Box,
  Button,
  ButtonGroup,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { firestore, functions } from 'config/firebase';
import { httpsCallable } from 'firebase/functions';
import { useToasts } from 'react-toast-notifications';

const validationSchema = yup.object({
  title: yup.string().required('Required'),
  body: yup.string().required('Required'),
});
//

export default function AppSendNotifications() {
  const { addToast } = useToasts();
  const [checked, setChecked] = useState([true, false]);

  const formik = useFormik({
    initialValues: {
      title: '',
      body: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      // try {

      // } catch (error) {

      // }finally{
      //   setSubmitting(false)
      // }
      // console.log(JSON.stringify(values, null, 2));2

      if (!checked?.some((e) => Boolean(e))) return null;

      const expo = checked[0];
      const inApp = checked[1];

      // return;
      const sendExpoAndInAppNotifications = httpsCallable(
        functions,
        'sendExpoAndInAppNotifications'
      );

      const { data } = await sendExpoAndInAppNotifications({
        ...values,
        expo,
        inApp,
      });

      if (data?.status === 'success') {
        resetForm();
      }

      setSubmitting(false);
      addToast(data?.message, { autoDismiss: true, appearance: data?.status });
    },
  });

  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChange2 = (event) => {
    setChecked([event.target.checked, checked[1]]);
  };

  const handleChange3 = (event) => {
    setChecked([checked[0], event.target.checked]);
  };

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel
        label="Send Expo Notification"
        control={<Checkbox checked={checked[0]} onChange={handleChange2} />}
      />
      <FormControlLabel
        label="Send In-App Notification"
        control={<Checkbox checked={checked[1]} onChange={handleChange3} />}
      />
    </Box>
  );

  return (
    <Card>
      <CardHeader
        title="Send Expo/In App Notifications"
        subheader="You can send only Expo notifications or only In App notifications or both"
      />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Box sx={{ justifyContent: 'center', width: '100%' }}>
          <Box>
            <TextField
              id="title"
              label="Notification Title"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!formik?.errors?.title}
              helperText={formik.errors.title}
              {...formik.getFieldProps('title')}
            />
            <TextField
              id="body"
              label="Notification Body"
              multiline
              rows={8}
              fullWidth
              margin="normal"
              error={!!formik?.errors?.body}
              helperText={formik.errors.body}
              {...formik.getFieldProps('body')}
            />

            <div>
              <FormControlLabel
                label="Send Both Notifications"
                control={
                  <Checkbox
                    checked={checked[0] && checked[1]}
                    indeterminate={checked[0] !== checked[1]}
                    onChange={handleChange1}
                    // {...formik.getFieldProps('send-to-all')}
                  />
                }
              />
              {children}
            </div>
            <ButtonGroup size="large" fullWidth disabled={formik.isSubmitting}>
              <Button variant="contained" color="error">
                Cancel
              </Button>
              <Button variant="contained" onClick={formik.handleSubmit}>
                Send
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
