import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCRmnKeb3z0h0BZ9sc2tu2kt6mk4Bi67O0',
  authDomain: 'the-outside-app.firebaseapp.com',
  projectId: 'the-outside-app',
  storageBucket: 'the-outside-app.appspot.com',
  messagingSenderId: '353812453059',
  appId: '1:353812453059:web:baa516ac0b27e90eef7476',
  measurementId: 'G-2Z10ZZRGEM',
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, 'europe-west1');
