import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import AddVenue from './pages/AddVenue';
import Venues from './pages/Venues';
import Event from './pages/Event';
import Events from './pages/Events';
import AddEvent from 'components/_dashboard/manage/AddEvent';

import { onAuthStateChanged } from 'firebase/auth';
import { useState, useEffect } from 'react';
import { auth } from 'config/firebase';
import UserEvents from 'pages/UserEvents';
import EditUserEvent from 'pages/EditUserEvent';
import ExtraEvents from 'pages/ExtraEvents';

// ----------------------------------------------------------------------

function RequireAuth({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  let location = useLocation();

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setLoading(false);
      } else {
        setUser(false);
        setLoading(false);
      }
    });

    return unsubscribeAuth;
  }, []);

  if (loading) {
    return null;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: (
        <RequireAuth>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'venues', element: <Venues /> },
        { path: 'manage/venue', element: <AddVenue /> },
        { path: 'manage/venue/:id', element: <AddVenue /> },
        { path: 'events', element: <Events /> },
        { path: 'user-events', element: <UserEvents /> },
        { path: 'extraevents', element: <ExtraEvents /> },
        { path: 'manage/event', element: <Event /> },
        { path: 'manage/event/:id', element: <Event /> },
        { path: 'manage/userevent/:id', element: <EditUserEvent /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
