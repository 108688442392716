import { countBy, filter, merge, pickBy, uniq, uniqBy } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';
import { useFirestoreCollectionData } from 'hooks/firebase';
import { collection, query } from 'firebase/firestore';
import { firestore } from 'config/firebase';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

const randomColors = (length) => {
  var COLORS = [];
  while (COLORS.length < length) {
    COLORS.push(`rgb(${rand(0, 255)}, ${rand(0, 255)}, ${rand(0, 255)})`);
  }

  // random number generator
  function rand(frm, to) {
    return ~~(Math.random() * (to - frm)) + frm;
  }

  return COLORS;
};
export default function AppCurrentVisits() {
  const [allUsers = [], loading] = useFirestoreCollectionData(
    query(collection(firestore, 'users')),
    {
      listen: true,
      transform: (data) => {
        return {
          ...data,
        };
      },
    }
  );

  const theme = useTheme();

  const CHART_DATA = Object.values(
    countBy(
      filter(
        allUsers?.map((u) => u?.deviceOSName),
        (o) => ['iOS', 'Android'].includes(o)
      )
    )
  );
  const uniqlabels = uniq(
    filter(
      allUsers?.map((u) => u?.deviceOSName),
      (o) => ['iOS', 'Android'].includes(o)
    )
  );

  const chartOptions = merge(BaseOptionChart(), {
    colors: uniqlabels?.map((ul, i) => randomColors(uniqlabels?.length)[i]),
    labels: uniqlabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card>
      <CardHeader title="Installs by device" />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="pie" series={CHART_DATA} options={chartOptions} height={280} />
      </ChartWrapperStyle>
    </Card>
  );
}
