import { useState, useEffect } from 'react';
import { onSnapshot, getDocs, doc, getDoc } from 'firebase/firestore';
import { get } from 'lodash';
import { firestore } from '../../config/firebase';

export const useFirestoreCollectionData = (collorQuery, options = { listen: false }) => {
  const [loading, setLoading] = useState(true);
  const [collectionData, setCollectionData] = useState(undefined);

  useEffect(() => {
    let unsubscribe = () => {};
    const q = collorQuery;
    const listen = get(options, 'listen', false);
    const idField = get(options, 'idField', '');
    const populateField = get(options, 'populate', false);
    const transform = get(options, 'transform', false);

    // retrieve data once
    if (!listen) {
      setLoading(true);
      getDocs(q).then((querySnapshot) => {
        const allDocs = [];
        querySnapshot.forEach((doc) => {
          if (doc.exists()) {
            const data = doc.data();
            if (idField) {
              data[idField] = doc.id;
            }
            allDocs.push(data);
          }
        });
        return snapshotToData(populateField, allDocs, transform, { setCollectionData, setLoading });
      });
    }

    // set up listener
    if (listen) {
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        setLoading(true);
        const allDocs = [];
        querySnapshot.forEach((doc) => {
          if (doc.exists()) {
            const data = doc.data();
            if (idField) {
              data[idField] = doc.id;
            }
            allDocs.push(data);
          }
        });

        return snapshotToData(populateField, allDocs, transform, { setCollectionData, setLoading });
      });
    }

    return unsubscribe;
  }, []);

  return [collectionData, loading];
};

const populate = async (populateField, data) => { 
  if (!Array.isArray(populateField) || populateField?.length < 1) {
    console.warn(
      `'populate' must be an array with object(s) of shape '{child: 'key', collection: 'name', storeAs: 'name'}'. It is currently being ignored`
    );
    return data;
  }

  const populatedData = [];

  for (let i = 0; i < data.length; i++) {
    const oldDoc = data[i];
    let document = oldDoc;
    for (let k = 0; k < populateField.length; k++) {
      const field = populateField[k];
      const child = get(field, 'child', '');
      const collection = get(field, 'collection', '');

      // how to store new data under document
      let storeAs = get(field, 'storeAs', undefined);
      storeAs = storeAs || child;

      const docRef = doc(firestore, collection, document[child]);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        document = { ...document, [storeAs]: docSnap.data() };
      } else {
        document = { ...document };
      }
    }

    populatedData.push(document);
  }

  return populatedData;
};

const transformAllData = (transform, allData) => {
  const transformed = allData.map((data) => {
    return transform(data);
  });

  return transformed;
};

const snapshotToData = (populateField, allDocs, transform, { setCollectionData, setLoading }) => {
  if (populateField) {
    return populate(populateField, allDocs).then((populatedDocs) => {
      if (transform instanceof Function) {
        setCollectionData(transformAllData(transform, populatedDocs));
        return setLoading(false);
      }
      setCollectionData(populatedDocs);
      return setLoading(false);
    });
  }
  if (transform instanceof Function) {
    setCollectionData(transformAllData(transform, allDocs));
    return setLoading(false);
  }
  setCollectionData(allDocs);
  return setLoading(false);
};
