import { useState, useEffect } from 'react';

import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Typography } from '@mui/material';
// layouts
// components
import { useFormik, FormikProvider, Formik } from 'formik';
import * as Yup from 'yup';
import { capitalize, isEqual, kebabCase } from 'lodash';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import {
  collection,
  doc,
  setDoc,
  Timestamp,
  where,
  query,
  getDocs,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import { firestore } from '../config/firebase';
import AddVenueForm from '../components/_dashboard/manage/AddVenueForm';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { getNewStorageDownloadURL } from 'utils';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useToasts } from 'react-toast-notifications';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  // margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  // minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(6, 0),
}));

// ----------------------------------------------------------------------

const AddVenueSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').required('Venue name required'),
  otherNames: Yup.string(),
  description: Yup.string(),
  category: Yup.string().required('Category is required'),
  location: Yup.object().required('Location is required'),
  website: Yup.string().url("Value must be a valid URL starting with 'https://www'"),
  phone: Yup.string().min(10, 'Must be 10 characters').max(10, 'Must be 10 characters'),
  openingTimes: Yup.string(),
  socialMediaHandles: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required('Name is required'), // these constraints take precedence
      handle: Yup.string().required('Handle is required'), // these constraints take precedence
    })
  ),
  images: Yup.string(),
});

const initialValues = {
  name: '',
  otherNames: '',
  description: '',
  category: '',
  location: '',
  phone: '',
  website: '',
  openingTimes: '',
  socialMediaHandles: [],
  images: '',
};

export default function AddVenue() {
  const { addToast } = useToasts();
  const navigate = useNavigate();

  // react-router
  let [searchParams] = useSearchParams();
  let { id } = useParams();

  const isEditing = !!id;
  const [initValues, setInitValues] = useState(initialValues);

  const [vcategories = []] = useCollectionData(collection(firestore, 'vcategories'));

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const slug = kebabCase(values?.name);
      const images = values?.images?.split(',');

      const results = await geocodeByAddress(values?.location?.label);
      const gps = await getLatLng(results[0]);

      // const res = await fetch(
      //   `https://maps.googleapis.com/maps/api/place/details/json?place_id=${values?.location?.value?.place_id}&key=AIzaSyBBQL3_SOU994mZEE0sIkeOeE1u_0uw0fM`
      // );

      // const placeDetails = await res.json();

      // console.log({ placeDetails });
      // return;

      if (!isEditing) {
        const findSlugSnapshot = await getDocs(
          query(collection(firestore, 'venues'), where('slug', '==', slug))
        );

        if (!findSlugSnapshot.empty) {
          return addToast('Venue already exists', { autoDismiss: true, appearance: 'error' });
        }

        const data = {
          ...values,
          otherNames: values?.otherNames ? values?.otherNames.split(',').map((v) => v.trim()) : [],
          category: vcategories?.find((category) => category?.id === values?.category)?.slug,
          slug,
          imgURLs: values?.images
            ? values?.images
                .split(',')
                .map((v) => v.trim().replaceAll('[img]', '').replaceAll('[/img]', ''))
            : [],
          images: values?.images
            ? values?.images
                .split(',')
                .map((v) => v.trim().replaceAll('[img]', '').replaceAll('[/img]', ''))
            : [],
          location: {
            name: values?.location?.label || '',
            coordinates: gps,
            place_id: values?.location?.value?.place_id || '',
            description: values?.location?.value?.description || '',
            types: values?.location?.value?.types || '',
          },
          venueIdentifierSlug: kebabCase(
            values?.location?.value?.structured_formatting?.main_text || slug || ''
          ),
          addedAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        };

        const newVenueRef = doc(collection(firestore, 'venues'));
        await setDoc(newVenueRef, { ...data, id: newVenueRef?.id });
        resetForm();
      }

      if (isEditing) {
        const data = {
          ...values,
          otherNames: values?.otherNames ? values?.otherNames.split(',').map((v) => v.trim()) : [],
          category: vcategories?.find((category) => category?.id === values?.category)?.slug,
          slug,
          location: {
            name: values?.location?.label || '',
            coordinates: gps,
            place_id: values?.location?.value?.place_id || '',
            description: values?.location?.value?.description || '',
            types: values?.location?.value?.types || '',
          },
          venueIdentifierSlug: kebabCase(
            values?.location?.value?.structured_formatting?.main_text || slug || ''
          ),
          updatedAt: Timestamp.now(),
        };

        const editVenueRef = doc(collection(firestore, 'venues'), values?.id);

        const newData = {
          ...data,
          imgURLs: values?.images
            ? values?.images
                .split(',')
                .map((v) => v.trim().replaceAll('[img]', '').replaceAll('[/img]', ''))
            : [],
          images: values?.images
            ? values?.images
                .split(',')
                .map((v) => v.trim().replaceAll('[img]', '').replaceAll('[/img]', ''))
            : [],
        };

        await updateDoc(editVenueRef, {
          ...newData,
        });

        navigate('/dashboard/venues', { replace: true });
      }

      addToast('Success', { autoDismiss: true, appearance: 'success' });
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (vcategories?.length) {
      if (isEditing) {
        (async () => {
          const q = await getDoc(doc(collection(firestore, 'venues'), id));
          const data = q.data();
          // console.log(vcategories?.find((category) => category?.slug === data?.category)?.id);

          setInitValues({
            ...data,
            otherNames: data?.otherNames.join(','),
            category: vcategories?.length
              ? vcategories?.find((category) => category?.slug === data?.category)?.id
              : '',
            // location: data?.location?.name,
            location: {
              label: data?.location?.name,
              value: {
                description: data?.location?.description,
                place_id: data?.location?.place_id,
                reference: data?.location?.place_id,
                types: data?.location?.types,
              },
            },
            images: data?.imgURLs?.join(','),
          });

          // formik.setFieldValue('location')
        })();
      } else {
        setInitValues(initialValues);
      }
    }
  }, [vcategories, isEditing]);

  return (
    <RootStyle title="Add/Edit Venue | Outside">
      <Formik
        initialValues={initValues}
        validationSchema={AddVenueSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          const { values } = formik;
          // console.log(values);
          return (
            <>
              <MHidden width="mdDown">
                <SectionStyle>
                  <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                    Venue Schema
                  </Typography>
                  {/* <img alt="register" src="/static/illustrations/illustration_register.png" /> */}
                  <Box sx={{}}>
                    <Typography
                      variant="subtitle2"
                      sx={{ px: 3, whiteSpace: 'pre-wrap' }}
                      // color="primary.secondary"
                    >
                      {`{
        "name": ${values?.name}, 
        "otherNames": [${values?.otherNames}],
        "slug": ${kebabCase(values?.name)}, 
        "description": ${capitalize(values?.description)}, 
         "category": ${
           vcategories?.find((category) => category?.id === values?.category)?.slug || ''
         }, 
         "location": { 
            "name":  ${values?.location?.label || ''}, 
            "place_id":  ${values?.location?.value?.place_id || ''}, 
            "coordinates": "", 
            "description": ${values?.location?.value?.description || ''}, 
            "types": ${values?.location?.value?.types || ''} 
          },
          "images": [${values?.images}],
          "phone": ${values?.phone}, 
          "website": ${values?.website}, 
          "socialMediaHandles": ${JSON.stringify(values?.socialMediaHandles)}, 
}`}
                    </Typography>
                  </Box>
                </SectionStyle>
              </MHidden>

              <Container>
                <ContentStyle>
                  <Box sx={{ mb: 5 }}>
                    <Typography variant="h4" gutterBottom>
                      {isEditing ? 'Edit Venue' : 'Add New Venue'}
                    </Typography>
                  </Box>

                  <AddVenueForm formik={formik} vcategories={vcategories} isEditing={isEditing} />
                </ContentStyle>
              </Container>
            </>
          );
        }}
      </Formik>
    </RootStyle>
  );
}
