import { Icon } from '@iconify/react';
import usergroupAddOutlined from '@iconify/icons-ant-design/usergroup-add-outlined';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection } from 'firebase/firestore';
import { firestore } from 'config/firebase';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter,
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.dark, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`,
}));

// ----------------------------------------------------------------------

export default function AppItemOrders() {
  const [users = []] = useCollectionData(collection(firestore, 'users'), {});
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={usergroupAddOutlined} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{users?.length}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        Total Users
      </Typography>
    </RootStyle>
  );
}
