import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill),
  },
  {
    title: 'manage',
    path: '/dashboard/manage',
    icon: getIcon('dashicons:admin-settings'),
    children: [
      {
        title: 'Add/Edit Venue',
        path: '/dashboard/manage/venue',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Add/Edit Event',
        path: '/dashboard/manage/event',
        icon: getIcon(pieChart2Fill),
      },
    ],
  },
  {
    title: 'Venues',
    path: '/dashboard/venues',
    icon: getIcon('eva:home-fill'),
  },
  {
    title: 'Events',
    path: '/dashboard/events',
    icon: getIcon('fontisto:bus-ticket'),
  },
  {
    title: 'users',
    path: '/dashboard/user',
    icon: getIcon('fontisto:person'),
  },
];

export default sidebarConfig;
