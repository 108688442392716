import { Form, FieldArray, Field } from 'formik';

// material
import {
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
  Button,
  ButtonGroup,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LoadingButton, LocalizationProvider, DateTimePicker } from '@mui/lab';
import { pick } from 'lodash';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

// ----------------------------------------------------------------------

export default function AddEvent({ formik, ecategories = [], venues, isEditing }) {
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } =
    formik;

  // console.log(getFieldProps('popularity'));

  return (
    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}> */}

        {/* <FormControl fullWidth error={Boolean(touched.images && errors.images)}>
          <FileUpload fieldName="images" />
          <FormHelperText>{touched.images && errors.images}</FormHelperText>
        </FormControl> */}

        <TextField
          fullWidth
          label="Name"
          {...getFieldProps('name')}
          error={Boolean(touched.name && errors.name)}
          helperText={touched.name && errors.name}
        />

        <TextField
          fullWidth
          label="Description"
          {...getFieldProps('description')}
          multiline
          rows={3}
          error={Boolean(touched.description && errors.description)}
          helperText={touched.description && errors.description}
        />
        {/* </Stack> */}

        {!isEditing && (
          <FormControl fullWidth error={Boolean(touched.location && errors.location)}>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ paddingBottom: 0.5, paddingLeft: 1.5 }}
            >
              Location
            </Typography>

            <GooglePlacesAutocomplete
              apiKey="AIzaSyBBQL3_SOU994mZEE0sIkeOeE1u_0uw0fM"
              autocompletionRequest={{
                componentRestrictions: {
                  country: ['gh'],
                },
              }}
              selectProps={{
                placeholder: 'Enter venue location',
                styles: {
                  input: (provided) => ({
                    ...provided,
                    padding: '12px 0',
                  }),
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '8px',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 10,
                  }),
                },
                value: values?.location,
                onChange: (e) => {
                  setFieldValue('location', e);
                },
              }}
            />
            <FormHelperText>{touched.location && errors.location}</FormHelperText>
          </FormControl>
        )}

        <FormControl fullWidth error={Boolean(touched.datetime && errors.datetime)}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="Date/Time"
              {...getFieldProps('datetime')}
              onChange={(value) => setFieldValue('datetime', value)}
            />
          </LocalizationProvider>
          <FormHelperText>{touched.datetime && errors.datetime}</FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel id="repeat">Repeat</FormLabel>
          <RadioGroup
            aria-labelledby="repeat"
            defaultValue={values?.repeat}
            name="repeat"
            sx={{ flexDirection: 'row' }}
            {...getFieldProps('repeat')}
          >
            <FormControlLabel value="" control={<Radio />} label="None" />
            <FormControlLabel value="daily" control={<Radio />} label="Daily" />
            <FormControlLabel value="weekly" control={<Radio />} label="Weekly" />
            <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
            <FormControlLabel value="yearly" control={<Radio />} label="Yearly" />
          </RadioGroup>
        </FormControl>

        <FormControl fullWidth error={Boolean(touched.noOfRepeat && errors.noOfRepeat)}>
          <InputLabel>Number of times to repeat</InputLabel>
          <Select
            {...getFieldProps('noOfRepeat')}
            label="Number of times to repeat"
            variant="outlined"
            type="number"
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30]?.map((c, i) => (
              <MenuItem key={i} value={c}>
                {c}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.noOfRepeat && errors.noOfRepeat}</FormHelperText>
        </FormControl>

        <FormControl fullWidth error={Boolean(touched.category && errors.category)}>
          <Autocomplete
            multiple
            disablePortal
            fullWidth
            getOptionLabel={(option) => option.name}
            options={ecategories}
            filterSelectedOptions
            {...getFieldProps('category')}
            onChange={(e, value) => setFieldValue('category', value)}
            renderInput={(params) => <TextField fullWidth {...params} label="Category" />}
          />

          <FormHelperText>{touched.category && errors.category}</FormHelperText>
        </FormControl>

        <TextField
          fullWidth
          label="Image URLs (separate with a comma)"
          {...getFieldProps('images')}
          multiline
          rows={3}
          error={Boolean(touched.images && errors.images)}
          helperText={touched.images && errors.images}
        />

        <TextField
          fullWidth
          label="Website"
          {...getFieldProps('website')}
          error={Boolean(touched.website && errors.website)}
          helperText={touched.website && errors.website}
        />

        <TextField
          fullWidth
          label="Activities"
          {...getFieldProps('activities')}
          multiline
          rows={3}
          error={Boolean(touched.activities && errors.activities)}
          helperText={touched.activities && errors.activities}
        />

        <TextField
          fullWidth
          label="Participants"
          {...getFieldProps('participants')}
          multiline
          rows={3}
          error={Boolean(touched.participants && errors.participants)}
          helperText={touched.participants && errors.participants}
        />

        <FormControl fullWidth error={Boolean(touched.popularity && errors.popularity)}>
          <InputLabel>Popularity</InputLabel>
          <Select {...getFieldProps('popularity')} label="Popularity" variant="outlined">
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((c, i) => (
              <MenuItem key={i} value={c}>
                {c}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.popularity && errors.popularity}</FormHelperText>
        </FormControl>

        <FieldArray
          name="ticketPrices"
          render={(arrayHelpers) => {
            // console.log(arrayHelpers);
            return (
              <>
                {values?.ticketPrices?.length ? (
                  <>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ paddingBottom: 0, paddingLeft: 1.5 }}
                    >
                      Ticket Prices
                    </Typography>
                    {values.ticketPrices.map((ticketPrice, index) => {
                      return (
                        <Stack key={index} direction="row" spacing={3}>
                          <Field name={`ticketPrices.${index}.name`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(errors);
                              return (
                                <TextField
                                  variant="standard"
                                  size="small"
                                  fullWidth
                                  label="Name"
                                  {...field}
                                  error={Boolean(
                                    touched.ticketPrices?.[index]?.name &&
                                      errors.ticketPrices?.[index]?.name
                                  )}
                                  helperText={
                                    touched.ticketPrices?.[index]?.name &&
                                    errors.ticketPrices?.[index]?.name
                                  }
                                />
                              );
                            }}
                          </Field>

                          <Field name={`ticketPrices.${index}.value`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(field, errors);
                              return (
                                <TextField
                                  variant="standard"
                                  size="small"
                                  fullWidth
                                  label="Value"
                                  {...field}
                                  error={Boolean(
                                    touched.ticketPrices?.[index]?.value &&
                                      errors.ticketPrices?.[index]?.value
                                  )}
                                  helperText={
                                    touched.ticketPrices?.[index]?.value &&
                                    errors.ticketPrices?.[index]?.value
                                  }
                                />
                              );
                            }}
                          </Field>

                          <ButtonGroup variant="outlined">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                arrayHelpers.insert(index + 1, { name: '', value: '' })
                              } // insert an empty string at a position
                            >
                              +
                            </Button>
                          </ButtonGroup>
                        </Stack>
                      );
                    })}
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => arrayHelpers.push({ name: '', value: '' })}
                  >
                    Ticket Prices
                  </Button>
                )}
              </>
            );
          }}
        />

        <FieldArray
          name="ticketPurchasePoints"
          render={(arrayHelpers) => {
            // console.log(arrayHelpers);
            return (
              <>
                {values?.ticketPurchasePoints?.length ? (
                  <>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ paddingBottom: 0, paddingLeft: 1.5 }}
                    >
                      Ticket Purchase Points
                    </Typography>
                    {values.ticketPurchasePoints.map((ticketPurchasePoint, index) => {
                      return (
                        <Stack key={index} direction="row" spacing={3}>
                          <Field name={`ticketPurchasePoints.${index}.type`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(errors);
                              return (
                                <FormControl
                                  fullWidth
                                  error={Boolean(
                                    touched.ticketPurchasePoints?.[index]?.type &&
                                      errors.ticketPurchasePoints?.[index]?.type
                                  )}
                                >
                                  <InputLabel>Type</InputLabel>
                                  <Select {...field} label="Type" variant="standard">
                                    {['phone', 'website', 'email', 'whatsapp']?.map((c, i) => (
                                      <MenuItem key={i} value={c}>
                                        {c}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText>
                                    {touched.ticketPurchasePoints?.[index]?.type &&
                                      errors.ticketPurchasePoints?.[index]?.type}
                                  </FormHelperText>
                                </FormControl>

                                // <TextField
                                //   variant="standard"
                                //   size="small"
                                //   fullWidth
                                //   label="Type"
                                //   {...field}
                                //   error={Boolean(
                                //     touched['ticketPurchasePoints']?.[index]?.type &&
                                //       errors['ticketPurchasePoints']?.[index]?.type
                                //   )}
                                //   helperText={
                                //     touched['ticketPurchasePoints']?.[index]?.type &&
                                //     errors['ticketPurchasePoints']?.[index]?.type
                                //   }
                                // />
                              );
                            }}
                          </Field>

                          <Field name={`ticketPurchasePoints.${index}.value`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(field, errors);
                              return (
                                <TextField
                                  variant="standard"
                                  size="small"
                                  fullWidth
                                  label="Value"
                                  {...field}
                                  error={Boolean(
                                    touched.ticketPurchasePoints?.[index]?.value &&
                                      errors.ticketPurchasePoints?.[index]?.value
                                  )}
                                  helperText={
                                    touched.ticketPurchasePoints?.[index]?.value &&
                                    errors.ticketPurchasePoints?.[index]?.value
                                  }
                                />
                              );
                            }}
                          </Field>

                          <ButtonGroup variant="outlined">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                arrayHelpers.insert(index + 1, { type: '', value: '' })
                              } // insert an empty string at a position
                            >
                              +
                            </Button>
                          </ButtonGroup>
                        </Stack>
                      );
                    })}
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => arrayHelpers.push({ type: '', value: '' })}
                  >
                    Ticket Purchase Points
                  </Button>
                )}
              </>
            );
          }}
        />

        <FieldArray
          name="socialMediaHandles"
          render={(arrayHelpers) => {
            return (
              <>
                {values?.socialMediaHandles?.length ? (
                  <>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ paddingBottom: 0, paddingLeft: 1.5 }}
                    >
                      Social Media Handles
                    </Typography>
                    {values.socialMediaHandles.map((friend, index) => {
                      return (
                        <Stack key={index} direction="row" spacing={3}>
                          <Field name={`socialMediaHandles.${index}.name`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(field, errors);
                              return (
                                <FormControl
                                  fullWidth
                                  error={Boolean(
                                    touched.socialMediaHandles?.[index]?.name &&
                                      errors.socialMediaHandles?.[index]?.name
                                  )}
                                >
                                  <InputLabel>Name</InputLabel>
                                  <Select {...field} label="Name" variant="standard">
                                    {['instagram', 'twitter', 'facebook']?.map((c, i) => (
                                      <MenuItem key={i} value={c}>
                                        {c}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText>
                                    {touched.socialMediaHandles?.[index]?.name &&
                                      errors.socialMediaHandles?.[index]?.name}
                                  </FormHelperText>
                                </FormControl>

                                // <TextField
                                //   variant="standard"
                                //   size="small"
                                //   fullWidth
                                //   label="Name"
                                //   {...field}
                                //   error={Boolean(
                                //     touched['socialMediaHandles']?.[index]?.name &&
                                //       errors['socialMediaHandles']?.[index]?.name
                                //   )}
                                //   helperText={
                                //     touched['socialMediaHandles']?.[index]?.name &&
                                //     errors['socialMediaHandles']?.[index]?.name
                                //   }
                                // />
                              );
                            }}
                          </Field>

                          <Field name={`socialMediaHandles.${index}.handle`}>
                            {({
                              field, // { name, value, onChange, onBlur }
                              form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                              meta,
                            }) => {
                              // console.log(field, errors);
                              return (
                                <TextField
                                  variant="standard"
                                  size="small"
                                  fullWidth
                                  label="Handle"
                                  {...field}
                                  error={Boolean(
                                    touched.socialMediaHandles?.[index]?.handle &&
                                      errors.socialMediaHandles?.[index]?.handle
                                  )}
                                  helperText={
                                    touched.socialMediaHandles?.[index]?.handle &&
                                    errors.socialMediaHandles?.[index]?.handle
                                  }
                                />
                              );
                            }}
                          </Field>

                          <ButtonGroup variant="outlined" aria-label="outlined button group">
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                arrayHelpers.insert(index + 1, { name: '', handle: '' })
                              } // insert an empty string at a position
                            >
                              +
                            </Button>
                          </ButtonGroup>
                        </Stack>
                      );
                    })}
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => arrayHelpers.push({ name: '', handle: '' })}
                  >
                    Add Social Media Handle
                  </Button>
                )}
              </>
            );
          }}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          {isEditing ? 'Update' : 'Add'}
        </LoadingButton>
      </Stack>
    </Form>
  );
}
