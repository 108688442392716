import { useState } from 'react';

// material
import {
  Card,
  Stack,
  Container,
  Typography,
  Avatar,
  Checkbox,
  TableRow,
  TableCell,
} from '@mui/material';
// components
import {
  collection,
  query,
  orderBy as forderBy,
  deleteDoc,
  doc,
  where,
  setDoc,
  runTransaction,
  getDocs,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';

//
import { DefaultTable, DefaultTablePagination } from '../components/common/DefaultTable';
import {
  applySortFilter,
  getComparator,
  handleChangePage,
  handleClick,
  handleFilterByName,
  handleRequestSort,
  handleSelectAllClick,
  handleChangeRowsPerPage,
} from '../utils';
import DefaultTableToolbar from '../components/common/DefaultTableToolbar';
import { firestore } from '../config/firebase';
import { useFirestoreCollectionData } from '../hooks/firebase';
import { capitalize, kebabCase, map } from 'lodash';

import { styled } from '@mui/material/styles';
import Label from 'components/Label';
import DefaultTableMoreMenu from 'components/common/DefaultTableMoreMenu';
import { capitalCase } from 'change-case';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'description', label: 'Description', alignRight: false },
  { id: 'venue', label: 'Venue', alignRight: false },
  { id: 'egoticketlink', label: 'Ticket Link', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'repeat', label: 'Repeat', alignRight: false },
  { id: 'stringTicketPrices', label: 'Ticket Prices', alignRight: false },
  { id: 'stringTicketPurchasePoints', label: 'Ticket Purchase Points', alignRight: false },
  { id: 'imgUrls', label: 'Image URLs', alignRight: false },

  { id: 'id', label: 'ID', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

const StyledA = styled('a')(({ theme }) => ({}));

export default function ExtraEvents() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [allEvents = [], loading] = useFirestoreCollectionData(
    query(collection(firestore, 'extraevents'), forderBy('updatedAt', 'desc')),
    {
      listen: true,
      transform: (data) => {
        // console.log({ data });
        return {
          ...data,
          venueName: data?.venue?.name,
          venueCoordinates: `${data?.venue?.location?.coordinates?.lat}, ${data?.venue?.location?.coordinates?.lng}`,
          stringTicketPrices: map(data?.ticketPrices, (v) => `${v?.name} - ${v?.value}`).join(', '),
          stringTicketPurchasePoints: map(data?.ticketPurchasePoints, (v) => `${v?.value}`).join(
            ', '
          ),
        };
      },
    }
  );

  // console.log(allEvents[0]);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allEvents.length) : 0;

  const filteredData = applySortFilter(allEvents, getComparator(order, orderBy), filterName);

  const isNotFound = !loading && filteredData.length === 0;

  const renderContent = () => {
    return filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
      const {
        id,
        name,
        datetime,
        venueName,
        venueCoordinates,
        repeat = '',
        images = [],
        stringTicketPurchasePoints,
        stringTicketPrices,
        egoticketlink,
        locationName,
      } = row;
      const isItemSelected = selected.indexOf(name) !== -1;

      // console.log({ venue });

      const onDelete = async (id) => {
        await deleteDoc(doc(collection(firestore, 'extraevents'), id));
      };

      return (
        <TableRow
          hover
          key={id}
          tabIndex={-1}
          role="checkbox"
          selected={isItemSelected}
          aria-checked={isItemSelected}
        >
          <TableCell padding="checkbox">
            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, name)} />
          </TableCell>
          <TableCell component="th" scope="row" padding="none">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar alt={name} src={images[0] || null} />
              <Typography variant="subtitle2" noWrap>
                {name}
              </Typography>
            </Stack>
          </TableCell>
          {/* <TableCell align="left">{description}</TableCell> */}
          <TableCell align="left">
            <StyledA
              sx={{
                color: 'primary.main',
              }}
              href={`https://www.google.com/maps/search/${venueCoordinates}/@${venueCoordinates}`}
              target="_blank"
            >
              {venueName}
            </StyledA>
          </TableCell>
          <TableCell align="left">
            <StyledA
              sx={{
                color: 'primary.main',
              }}
              href={egoticketlink}
              target="_blank"
            >
              {venueName}
            </StyledA>
          </TableCell>
          <TableCell align="left">{datetime.toDate().toGMTString()}</TableCell>
          <TableCell align="left">{capitalCase(repeat)}</TableCell>
          <TableCell align="left">{stringTicketPrices}</TableCell>
          <TableCell align="left">{stringTicketPurchasePoints}</TableCell>
          <TableCell align="left">{images.join(',')}</TableCell>

          <TableCell align="left">{id}</TableCell>
          <TableCell align="right">
            <DefaultTableMoreMenu to={``} onDelete={() => {}} />
          </TableCell>
        </TableRow>
      );
    });
  };

  const addEgoTicketEventsToEvents = async () => {
    const batch = writeBatch(firestore);

    for (let s of selected) {
      const egoDocRef = doc(collection(firestore, 'extraevents'), s?.id);
      const docRef = doc(collection(firestore, 'events'));

      delete s?.stringTicketPrices;
      delete s?.stringTicketPurchasePoints;
      delete s?.venueName;
      delete s?.venueCoordinates;

      // console.log({ s });
      const docsRef = await getDocs(
        query(collection(firestore, 'events'), where('slug', '==', s?.slug))
      );

      if (!docsRef?.empty) {
        const doclength = docsRef.docs.length;
        if (doclength === 1) {
          const document = docsRef.docs[0].data();
          if (document.date !== s?.date) {
            batch.set(docRef, {
              ...s,
              id: docRef?.id,
              addedAt: Timestamp.now(),
              updatedAt: Timestamp.now(),
            });
          }
        }
      }

      if (docsRef?.empty) {
        batch.set(docRef, {
          ...s,
          id: docRef?.id,
          addedAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        });
      }

      // console.log('here');
      batch.delete(egoDocRef);
    }

    await batch.commit();
    setSelected([]);
  };

  const deleteExtraEvents = async () => {
    const batch = writeBatch(firestore);

    for (let s of selected) {
      const egoDocRef = doc(collection(firestore, 'extraevents'), s?.id);
      batch.delete(egoDocRef);
    }

    await batch.commit();
    setSelected([]);
  };

  return (
    <Page title="EgoTicket Events | Outsida">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            EgoTicket Events
          </Typography>
        </Stack>

        <Card>
          <DefaultTableToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={(e) => handleFilterByName(e, { setFilterName })}
            addEgoTicketEventsToEvents={addEgoTicketEventsToEvents}
            deleteExtraEvents={deleteExtraEvents}
          />

          <Scrollbar>
            <DefaultTable
              renderContent={renderContent}
              TABLE_HEAD={TABLE_HEAD}
              data={allEvents}
              emptyRows={emptyRows}
              filterName={filterName}
              filteredData={filteredData}
              handleClick={(e, name) => handleClick(e, name, { selected, setSelected })}
              handleRequestSort={(event, property) =>
                handleRequestSort(event, property, { order, orderBy, setOrder, setOrderBy })
              }
              handleSelectAllClick={(e) => handleSelectAllClick(e, allEvents, { setSelected })}
              isNotFound={isNotFound}
              loading={loading}
              order={order}
              orderBy={orderBy}
              page={page}
              rowsPerPage={rowsPerPage}
              selected={selected}
            />
          </Scrollbar>

          <DefaultTablePagination
            data={allEvents}
            handleChangePage={(e, newPage) => handleChangePage(e, newPage, { setPage })}
            handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e, { setPage, setRowsPerPage })}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
